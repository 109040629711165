import React, { useState, useEffect } from "react"
import { Autocomplete, TextField } from "@mui/material"
import Button from "components/shared/Button/Button"
import Input from "components/shared/Inputs/Inputs"
import SearchTable from "components/shared/SearchTable/SearchTable"
import { Col, Card, CardBody, Container, Row } from "reactstrap"
import api from "../../services/api"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import AuthService from "../../services/Auth.service"
import Select from "react-select";

const EquipamentoKit = () => {
  const initialState = {
    id:"",
    hydro: "",
    lora: "",
    location: "",
    region: "",
    locationType: "",
  }

  const user = AuthService.getCurrentUser()

  
  const [selectedCond, setSelectedCond] = useState(null);

  


  let checkempresa = null
  let DS_STATUS = null
  if (user != null) {
    DS_STATUS = user.user.DS_STATUS
    if (user.empresa[0] != undefined) {
      checkempresa = user.empresa[0].OID_EMPRESA
    }
  }
  const Empresa = {
    OID_EMPRESA: "",
    NM_RAZAO_SOCIAL: "",
  }
  let stateempresa = null

  const regionArray = [
    { id: "", region: "Selecione..." },
    { id: "NORTE", region: "Norte" },
    { id: "SUL", region: "Sul" },
    { id: "LESTE", region: "Leste" },
    { id: "OESTE", region: "Oeste" },
  ]
  const locationTypeArray = [
    { id: "", locationType: "Selecione..." },
    { id: "F", locationType: "Final" },
    { id: "T", locationType: "Temporária" },
  ]

  let [lc, setLc] = useState(Empresa)
  const [lorae, setLorae] = useState(null)

  // let [makers, setMakers] = useState()
  const [makers, setMakers] = useState([])
  const [condos, setCondo] = useState([])

  const [search, setSearch] = useState(initialState)

  const [values, setValues] = useState([])

  const [hydro, setHydro] = useState([])
  const [medidor, setMedidorFilter] = useState([])
  const [loraf, setLoraFilter] = useState([])
  const [lora, setLora] = useState([])
  const [selectedLora, setSelectedLora] = useState(null)
  const [showList, setShowList] = useState(false)



  const handleAutocompleteChange = (newValue) => {
   
    if (newValue) {
   
      setLoraFilter({ ...search, ["lora"]: newValue }); // Define o valor selecionado no campo de autocomplete
      setSelectedLora(newValue.value)
    
    }
  };

  const fetchMakers = () => {
    let maker = [{ maker: "Selecione..." }]
    api.get("/imovel/hidro/" + user.user.OID_USUARIO).then(response => {
      response.data.map(elem => {
        maker.push({
          value: elem.OID_MEDIDOR,
          label: elem.NR_MEDIDOR,
        })
      })
      setMakers(maker)
    })
  }

  // console.log('loraf', loraf);

  const fetchHidroSindico = () => {
    let maker = [{ id: "", maker: "Selecione..." }]
    api.get("/imovel/empresa/" + checkempresa).then(response => {
      response.data.map(elem => {
        maker.push({
          value: elem.OID_MEDIDOR,
          label: elem.NR_MEDIDOR,
        })
      })
      setMakers(maker)
    })
  }

  const fetchLoraEmpresa = () => {
    let lora = [{ id: "", lora: "Selecione..." }]
    api.get("/equipmentslora/empresa/" + stateempresa).then(response => {
      response.data.map(elem => {
        lora.push({
          value: elem.OID_EQUIPAMENTO_LORA,
          label: elem.NR_EQUIPAMENTO,
        })
      })
      setLora(lora)
    })
  }

  const fetchCondo = () => {
    let condo = [{ id: "", condo: "Selecione..." }]
    api.get("/empresa/").then(response => {
      response.data.map(elem => {
        condo.push({
          value: elem.OID_EMPRESA,
          label: elem.NM_RAZAO_SOCIAL,
        })
      })
      setCondo(condo)
    })
  }

  if(DS_STATUS != 10){

  useEffect(() => {
    
    let maker = [{ id: "", maker: "Selecione..." }]
    if(loraf.lora){
  
    api.get("/registrosportas/modulo/" + loraf.lora.value).then(response => {
      response.data.map(elem => {
        maker.push({
          value: elem.OID_MEDIDOR,
          label: "Medidor: " + elem.NR_MEDIDOR + " - " + elem.imovel,
        })
      })
      setMakers(maker)
     
    })
  }
    
   
  
  console.log(makers);
}, [loraf.lora]);
  }

  useEffect(() => {
      console.log(loraf.lora)
      let maker = [{ id: "", maker: "Selecione..." }]
      if(loraf.lora){
    
      api.get("/registrosportas/modulo/" + loraf.lora.value).then(response => {
        response.data.map(elem => {
          maker.push({
            value: elem.OID_MEDIDOR,
            label: "Medidor: " + elem.NR_MEDIDOR + " - " + elem.imovel,
          })
        })
        setMakers(maker)
       
      })

    }
     
    
      console.log(makers);
  }, [loraf.lora]);

  useEffect(() => {
    fetchHidroSindico()
  }, [])

  if (DS_STATUS == 10) {
    useEffect(() => {
      fetchCondo()
    }, [])
  }
  const fetchHidroCondo = () => {
    let maker = [{ id: "", maker: "Selecione..." }]
    api.get("/imovel/empresa/" + stateempresa).then(response => {
      response.data.map(elem => {
        maker.push({
          value: elem.OID_MEDIDOR,
          label: "Medidor: " + elem.NR_MEDIDOR + " - " + elem.imovel,
        })
      })
      setMakers(maker)
    })
  }

  const onChangeHandlerlora = prop => event => {
    setLoraFilter({ ...search, [prop]: event.target.value })
  }

  const onChangeHandlermedidor = prop => event => {
    console.log(event.target.value)
    setMedidorFilter({ ...search, [prop]: event.target.value })
    console.log(medidor)
  }

  const fetchHydro = () => {
    let hydro = [{ id: "", lora: "Selecione..." }]
    api.get("/hydrometers").then(response => {
      response.data.map(elem => {
        hydro.push({
          value: elem.OID_MEDIDOR,
          label: elem.NR_MEDIDOR,
        })
      })
      setHydro(hydro)
    })
  }

  const fetchLora = id => {
    if (DS_STATUS == 10) {
      checkempresa = 0
    }

    let lora = [{ id: "", lora: "Selecione..." }]
    api.get("/equipmentslora/empresa/" + id).then(response => {
      
      response.data.map(elem => {
        lora.push({
          value: elem.OID_EQUIPAMENTO_LORA,
          label: elem.NR_EQUIPAMENTO,
        })
      })
      setLora(lora)
    
    })
  }

  const onChangeHandler = prop => event => {
    setSearch({ ...search, [prop]: event.target.value })
  }

  const submitHandler = event => {
    event.preventDefault()
    if (DS_STATUS == 10) {
      checkempresa = null
    }
    setValues([])
    let arr = []
    setShowList(false)


    let body = {
      OID_MEDIDOR: medidor.maker,
      OID_EQUIPAMENTO_LORA: loraf.lora?.value,
      DS_LOCAL_INSTALACAO: search.region,
      OID_EMPRESA: checkempresa,
    }
    // console.log(body)
    // console.log(loraf)
    // console.log("med"+JSON.stringify(search))
    // console.log("med"+JSON.stringify(medidor.maker))

    api.post("/registros/filter", body).then(response => {
      if (response.data.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "Nenhum Kit registrado ao Módulo",
        })
        setShowList(false)
      } else {
        arr = formatArray(response.data)
        Swal.fire({
          icon: "success",
          timer: 500,
        })
        console.log("?"+JSON.stringify(response.data))
        setValues(arr)
        setShowList(true)
        setSearch(initialState)
      }
    })
  }

  const formatArray = data => {
    let aux = []
    data.map(kit => {
      lora.map(lora => {
        hydro.map(hydro => {
          if (
            kit.OID_EQUIPAMENTO_LORA === lora.value &&
            kit.OID_MEDIDOR === hydro.value
          ) {
            aux.push({
              idKit: kit.OID_REGISTRO,
              idHydro: hydro.value,
              idLora: lora.value,
              hydro: hydro.label,
              lora: lora.label,
              location: kit.DS_LOCAL_INSTALACAO,
              clock: kit.DS_RELOJOARIA_INICIAL,
              porta: kit.PORTA,
              data: kit.DT_ULT_ALTER
            })
          }
        })
      })
    })
    console.log(aux+"aux")
    
    return aux
  }

  useEffect(() => {
    fetchHydro()
  }, [])

  useEffect(() => {
    setLorae({ lora: "" })

  }, [condos])

  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Pesquisa | Equipamento KIT</label>
        <div className="component-form " style={{ margin: "0px" }}>
       
       
          <div className="">
          {/* <Card>
          <CardBody> */}

            <div className="row" style={{ textAlign: "end" }}>
              {DS_STATUS == 10 ? (
                <div className="col-12" style={{ alignSelf: "center" }}>
                  <Button
                    icon="ti-agenda"
                    text="Cadastrar"
                    link="/cadastro/equipamentokit-cadastro"
                  />
                </div>
              ) : null}
            </div>


            {DS_STATUS == 10 ? (
            <div className="row col-5" style={{ marginBottom: 10 }}>
               
               <label
                     htmlFor="Condominio"
                     className="mt-1"
                   >
                     Condomínio
                   </label>
                   <Select
                  
                     label="Condominio"
                     id="inlineFormSelectPrefc"
                   placeholder="Selecione..."
                   onChange={(newValue) => {
            
                     setLc({ ...lc, ["value"]: newValue })
                     stateempresa = newValue.value
                     fetchLora(newValue.value)
                     fetchHidroCondo()
                     setSelectedCond(newValue);
                   }}
                   styles={{
                 
                     menu: (provided) => ({
                       ...provided,
                       backgroundColor: 'white', // Set background color for the dropdown list
                       color: 'black', // Set text color for the dropdown list
                     })
                   }}
                   options={condos}
                   value={selectedCond}
                   
                   />
          
               
               </div>

                ) : null}
               <div className="row col-5" style={{ marginBottom: 10 }}>
                

               <label
                     htmlFor="Loras"
                     className="mt-1"
                   >
                     Módulo Lora
                   </label>
                   <Select
                  
                     label="Número Medidor"
                     id="inlineFormSelectPref"
                   placeholder="Selecione..."
                   
                   onChange={handleAutocompleteChange}
                   styles={{
                 
                     menu: (provided) => ({
                       ...provided,
                       backgroundColor: 'white', // Set background color for the dropdown list
                       color: 'black', // Set text color for the dropdown list
                     })
                   }}
                   options={lora}
                  //  getOptionLabel={option => option.label + (option.label ? ` - ${option.label}` : "")} 
                   value={loraf.lora}
                   />

               </div>


               <div className="row col-5" style={{ marginBottom: 10 }}>
                

                <label
                      htmlFor="Loras"
                      className="mt-1"
                    >
                      Número do Medidor
                    </label>
                    <Select
                   
                      label="Número Medidor"
                      id="inlineFormSelectPref"
                    placeholder="Selecione..."
                    onChange={(novoValor) => {
                      setMedidorFilter({ ...search, ["maker"]: novoValor.value })
                      
                    }}
                    styles={{
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={makers}
                    // getOptionLabel={opcao => opcao.maker}
                    value={search.maker}
                    />
 
                </div>


        

            {/* <div className="row">
              <div className="col-6">
                <Input
                  id="lora"
                  label="Equipamento Lora"
                  value={search.lora}
                  onChange={onChangeHandler("lora")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-6">
                <Input
                  id="location"
                  label="Localidade"
                  value={search.location}
                  onChange={onChangeHandler("location")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div> */}

            {/* <div className="row">
              <div className="col-6">
                <Input
                  id="locationType"
                  label="Tipo de Localização"
                  type="select"
                  options={[locationTypeArray, "locationType"]}
                  value={search.locationType}
                  onChange={onChangeHandler("locationType")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div> */}
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                <Button
                  icon="ti-search"
                  text="Pesquisar"
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                />
              </div>
            </div>
            {/* </CardBody>
          
          </Card> */}
          </div>
        
        </div>
        {console.log("?"+JSON.stringify(values))}
        {showList && (
          <Container fluid>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Medidor</th>
                  <th className="align-middle">Descrição Modulo</th>
                  <th className="align-middle">Localização</th>
                  <th className="align-middle">Relog. Inic.</th>
                  <th className="align-middle">Porta</th>
                  <th className="align-middle">Data Instalação</th>
                  
                  <th className="align-middle"></th>
                  
                </tr>
              </thead>
              <tbody>
                {values?.map((kit, key) => (
                  
                  <tr key={key}>
                  <td>{kit.hydro}</td>
                  <td>{kit.lora}</td>
                  <td>{kit.location}</td>
                  <td>{kit.clock}</td>
                  <td>{kit.porta}</td>
                  <td>{kit.data !== null
                        ? new Date(kit.data).toLocaleString("pt-BR", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit"
                          })
                        : ""}</td>
                  <td>
                    <Link
                      type="button"
                      to={`/cadastro/equipamentokit-update/${kit.idKit}`}
                      color="link"
                      size="sm"
                      className="btn-light waves-effect waves-light"
                    >
                      <i className="dripicons-document-edit" />
                    </Link>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Container>
        // <SearchTable
        //   tableHead={[
        //     "Medidor",
        //     "Número Equip. Lora",
        //     "Localização",
        //     "Relog. Inic.",
        //     "Porta",
        //     "",
        //   ]}
          
        // >
        //   {values?.map((kit, key) => (
        //     <tr key={key}>
        //       <td>{kit.hydro}</td>
        //       <td>{kit.lora}</td>
        //       <td>{kit.location}</td>
        //       <td>{kit.clock}</td>
        //       <td>{kit.porta}</td>
        //       <td>
        //         <Link
        //           type="button"
        //           to={`/cadastro/equipamentokit-update/${kit.idKit}`}
        //           color="link"
        //           size="sm"
        //           className="btn-light waves-effect waves-light"
        //         >
        //           <i className="dripicons-document-edit" />
        //         </Link>
        //       </td>
        //     </tr>
        //   ))}
        // </SearchTable>
      )}
      </div>
    </React.Fragment>
  )
}

export default EquipamentoKit
