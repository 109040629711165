import React, { useState, useEffect } from "react";

import Button from "components/shared/Button/Button";
import Input from "components/shared/Inputs/Inputs";
import SearchTable from "components/shared/SearchTable/SearchTable";
import {Button as button} from 'reactstrap';
import { Col, Card, CardBody, Container, Row, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Table} from 'reactstrap';
import { useNavigate, useParams ,Link } from "react-router-dom";

import Swal from "sweetalert2";

import api from "../../../services/api";

const Payload = () => {
  const initialState = {
    maker: "",
    protocol: "",
    protocolVersion: "",
    payloadType: "",
    base: "",
  };

  let navigate = useNavigate();
  let { id } = useParams();

  const [search, setSearch] = useState(initialState);
  const [makers, setMakers] = useState([]);

  const [values, setValues] = useState([]);

  const [showList, setShowList] = useState(false);

  const statusArray = [
    { id: "S", status: "Ativo" },
    { id: "N", status: "Inativo" },
  ];

  // const fetchHydrometersId = () => {
  //   api.get(`/makerspayloads/${id}`).then((response) => {
  //     let obj = response.data[0];
  //     obj = {
  //       makerId: obj.id,
  //       id: obj.OID_FABRICANTE_LORA_PAYLOAD,
  //       ativo: obj.TP_ATIVO,
  //       makerName: obj.maker,
  //       protocol: obj.DS_PROTOCOLO,
  //       protocolVersion: obj.NR_PROTOCOLO_VERSAO,
  //       payloadType: obj.TP_PAYLOAD,
  //       base: obj.NR_BASE_DECRIPTACAO,
  //     };
  //     setSearch(obj);
  //   });
  // };

  // useEffect(() => {
  //   fetchHydrometersId();
  // }, []);

  const [password, setPassword] = useState('');
  const [passwordEntered, setPasswordEntered] = useState(false);
  const [ida, setIda] = useState("")
  const [modalida, setmodalida] = useState(false);
  const [editedIda, setEditedIda] = useState('');
  const [userData, setUserData] = useState({})


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === 'token') {
      setPasswordEntered(true);
    } else {
      alert('Senha inválida'); // You can replace alert with a more sophisticated error handling approach
    }
  };

  const toggleViewModalIda = () => {
    setEditedIda(ida);
    setmodalida(!modalida);
   
  };


  const handleInputIda = (event) => {
    setEditedIda(event.target.value);
  };


  const handleSaveida = () => {
    setmodalida(!modalida);
    // Exit edit mode
    // Perform save logic here
    console.log(search)
    let data = {
    
        
      TP_ATIVO: userData.ativo
    }
  
  

    
    api.put("/makerspayloads/statusupdate/"+userData.id, data ).then(() => {
        Swal.fire({
          icon: "success",
          title: "Status Alterado!",
        })
          .then(() => {
            navigate(`/configuracao/payload`);
            window.location.reload(true)
          })
          .catch(() => {
            Swal.fire({
              icon: "warning",
              title: "Erro!",
            });
          });
      });
  
  };

  const fetchMakers = () => {
    let maker = [{ id: "", maker: "Selecione..." }];
    api.get("/makerslora").then((response) => {
      response.data.map((elem) => {
        maker.push({
          id: elem.OID_FABRICANTE_LORA,
          maker: elem.DS_FABRICANTE_LORA,
        });
      });
      setMakers(maker);
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    let body = {
      OID_FABRICANTE_LORA: search.maker,
      DS_PROTOCOLO: search.protocol,
      NR_PROTOCOLO_VERSAO: search.protocolVersion,
      TP_PAYLOAD: search.payloadType,
      NR_BASE_DECRIPTACAO: search.base,
    };
    api
      .post("/makerspayloads/filter", body)
      .then((response) => {
        const arr = formatArray(response.data);
        if (arr.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setShowList(false);
        } else {
          Swal.fire({
            icon: "success",
            timer: 500,
          });
          setShowList(true);
          setValues(arr);
          setSearch(initialState);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          text: "Verifique os dados e refaça a pesquisa",
        });
        setShowList(false);
      });
  };

  const formatArray = (data) => {
    let array = [];
    data.map((payload) => {
      makers.map((maker) => {
        if (payload.OID_FABRICANTE_LORA === maker.id) {
          array.push({
            makerId: maker.id,
            id: payload.OID_FABRICANTE_LORA_PAYLOAD,
            ativo: payload.TP_ATIVO,
            makerName: maker.maker,
            protocol: payload.DS_PROTOCOLO,
            protocolVersion: payload.NR_PROTOCOLO_VERSAO,
            payloadType: payload.TP_PAYLOAD,
            base: payload.NR_BASE_DECRIPTACAO,
          });
        }
      });
    });
    return array;
  };

  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const onChangeHandlers = (field) => (event) => {
    setUserData({
      ...userData,
      [field]: event.target.value,
    });
  };

  useEffect(() => {
    fetchMakers();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Pesquisa | Payload</label>
        <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">
            <div className="row" style={{ textAlign: "end" }}>
              <div className="col-6" style={{ textAlign: "start" }}>
                <Input
                  id="fab-search"
                  label="Fabricante Lora"
                  options={[makers, "maker"]}
                  type="select"
                  value={search.maker}
                  onChange={onChangeHandler("maker")}
                  styles={{ width: "100%" }}
                />
              </div>
              <div className="col-6" style={{ alignSelf: "center" }}>
                <Button
                  icon="ti-agenda"
                  text="Cadastrar"
                  link="/configuracao/payload-cadastro"
                  styles={{ width: "20%", marginBottom: "1.5rem" }}
                />
              </div>
            </div>
            <div className="row" style={{ textAlign: "start" }}>
              <div className="col-6">
                <Input
                  id="protocol"
                  label="Protocolo"
                  value={search.protocol}
                  onChange={onChangeHandler("protocol")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row" style={{ textAlign: "start" }}>
              <div className="col-6">
                <Input
                  id="protocolVersion"
                  label="Versão do Protocolo"
                  value={search.protocolVersion}
                  onChange={onChangeHandler("protocolVersion")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row" style={{ textAlign: "start" }}>
              <div className="col-6">
                <Input
                  id="payloadType"
                  label="Tipo do Payload"
                  value={search.payloadType}
                  onChange={onChangeHandler("payloadType")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row" style={{ textAlign: "start" }}>
              <div className="col-6">
                <Input
                  id="base"
                  label="Base Criptografia"
                  value={search.base}
                  onChange={onChangeHandler("base")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                <Button
                  icon="ti-search"
                  text="Pesquisar"
                  styles={{ marginLeft: "2.5rem" }}
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                />
              </div>
            </div>
          </div>
        </form>
        {/* <SearchTable
          tableHead={[
            "Fabricante",
            "Protocolo",
            "Versão do Protocolo",
            "Tipo Payload",
            "Base Criptografia",
            "Status",
          ]}
          showTable={showList}
          tableBody={[values, 3]}
        >
          {values?.map((elem, key) => (
            <td key={key}>
              {elem.ativo == "S" ? (
                <span className="badge bg-success">Ativo</span>
              ) : (
                <span className="badge bg-danger bg-primary">Inativo</span>
              )}
            </td>
          ))}
        </SearchTable> */}
         {showList && (
          <Container fluid>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Fabricante</th>
                  <th className="align-middle">Protocolo</th>
                  <th className="align-middle">Versão do Protocolo</th>
                  <th className="align-middle">Tipo Payload</th>
                  
                  <th className="align-middle">Base Criptografia</th>
                 
                  <th className="align-middle">Status</th>
                  <th className="align-middle"></th>
                  
                </tr>
              </thead>
              <tbody>
                {values?.map((value, key) => (
                  
                  <tr key={key}>
                  <td>{value.makerName}</td>
                  <td>{value.protocol?value.protocol :"Sem Descrição" }</td>
                  <td>{value.protocolVersion}</td>
                  <td>{value.payloadType}</td>
                  <td>{value.base}</td>

                 
                  
                  <td>
                  {value.ativo == "S" ? (
                <span className="badge bg-success">Ativo</span>
                  ) : (
                    <span className="badge bg-danger bg-primary">Inativo</span>
                  )}
                  </td>
                  <td>

                  <a
                              style={{ left: "5%", bottom:"15%" }}
                              type="button"
                              onClick={() => { toggleViewModalIda(); setUserData(value); }}
                              // onClick={toggleViewModalIda}
                              color="link"
                              size="sm"
                              className="btn btn-light waves-effect waves-light"
                            >
                              <i className="dripicons-document-edit" />
                            </a>
                    {/* <Link
                      type="button"
                      to={`/configuracao/payload-update/${value.id}`}
                      color="link"
                      size="sm"
                      className="btn-light waves-effect waves-light"
                    >
                      <i className="dripicons-document-edit" />
                    </Link> */}
                  </td>
                  {/* <td>
                  <i onClick={() => handleButtonClick(value.id)} className="dripicons-trash" style={{ cursor: 'pointer' }} /> 
                  </td> */}
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Container>
          )}
      </div>

      <Modal
        isOpen={modalida}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodalida(!modalida);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodalida(!modalida);
            }}
          >
           
           Payload</ModalHeader>
          <ModalBody>
            <p className="mb-2">
          
            </p>
         
            <div>
      {!passwordEntered ? (
        <>
        <div className="password-input">
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={handlePasswordChange}
          />
          {/* <button className="confirm-button" onClick={handlePasswordSubmit} >Entrar</button> */}
          {/* <button onClick={handlePasswordSubmit}>Submit</button> */}
        </div>
        <div style={{ margin:"5px" }}>

        <button className="confirm-button"   onClick={handlePasswordSubmit} >Entrar</button>

        </div>
       
         </>
      ) : (
        <div className="table-responsive">
          <Table className="table table-centered table-nowrap">
            <thead>
              <tr>
                <th scope="col">Editar Status </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    {/* <Input
                      id="number"
                      value={editedIda}
                      onChange={handleInputIda}
                      styles={{ width: '100%' }}
                    /> */}

                       <Input
                  id="base"
                  type="select"
                  
                  value={userData.ativo}
                  options={[statusArray, "status"]}
                  onChange={onChangeHandlers("ativo")}
                  styles={{ width: "100%", marginBottom: "0.5rem" }}
                />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
          </ModalBody>
          <ModalFooter>
          {passwordEntered ? (
          <button className="confirm-button" onClick={handleSaveida} >Salvar</button>
        ) : (

          "" )}
            <button
              type="button"
              color="secondary"
            
              onClick={() => {
                setmodalida(!modalida);
              }}
            >
              Fechar
            </button>
          </ModalFooter>


      
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Payload;