import React, { useState,useRef, useEffect, useCallback } from 'react';
// import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

import { MdClose } from 'react-icons/md';
import { Col, Card, CardBody, Container, Row, Button , Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Table} from 'reactstrap';
import { useSpring, animated } from 'react-spring';
 import styled from 'styled-components';
 import "./Modal.css"
 import api from '../../services/api';
//  import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
 import Input from "components/shared/Inputs/Inputs";
 import Swal from "sweetalert2";
 import { useForm } from "react-hook-form";
 

function VerMorador(props) {
    const [name, setName] = useState(props.name);
    const [id, setRole] = useState(props.id);

    const [showR, setShowR] = useState(false);
    const [imovel, setImovel] = useState({})
    const handleClose = () => setShowR(false);
    const handleShowR = () => setShowR(true);

    const [modalr, setmodalr] = useState(false);


    const toggleViewModalr = () => {
      
      setmodalr(!modalr);
      console.log(id)
    };

    useEffect(() => {
      api.get('/imovel/user/imovel/'+id).then((response) => {
        let body = {
          nome: response.data[0].DS_NOME +" "+ response.data[0].DS_SOBRENOME,
          email: response.data[0].DS_EMAIL,
          telefone: response.data[0].NR_TELEFONE,
          NR_IDA: response.data[0].NR_IDA,
          medidor: response.data[0].NR_MEDIDOR,
          PORTA: response.data[0].PORTA,
          TP_MEDIDOR: response.data[0].TP_MEDIDOR,
          Pulso: response.data[0].NR_CONSUMO_LITROS,
          DS_RELOJOARIA_INICIAL: response.data[0].DS_RELOJOARIA_INICIAL,
          DS_DESCRICAO_EQUIPAMENTO: response.data[0].DS_DESCRICAO_EQUIPAMENTO,
          DS_MODELO_MEDIDOR: response.data[0].DS_MODELO_MEDIDOR,
          OID_USUARIO: response.data[0].OID_USUARIO,
        };
        setImovel(body)
        console.log(response.data[0])
            console.log(imovel)
           
      });
    }, []);
  
  

    const Background = styled.div`
    width: 80%;
    height: 80%;
    
    
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  
  const ModalWrapper = styled.div`
    width: 700px;
    min-height: 300px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    
    
    position: relative;
    z-index: 10;
    border-radius: 10px;
  `;
  
  const ModalImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background: #000;
  `;
  
  const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    
    line-height: 1.8;
    color: #141414;
  
    p {
      margin-bottom: 1rem;
    }
  
    button {
      padding: 10px 24px;
      
      border: none;
    }
  `;
  
  const div1 = styled.div`
   
   text-align: center;
  
  `;

  const dadosmed = styled.div`
   
  white-space: pre-line;
  
  `;

  

  const Divso = styled.div`
   
  border-bottom: 1px solid !important;
  padding-top: 5px;
  
  
  `;
  
  const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
  `;
  
  const [data, setData] = useState([])


  const [pessoa, setPessoa] = useState({})

  const {reg,setValue,getValues} = useForm();



    const animation = useSpring({
        config: {
          duration: 250
        },
        opacity: showR ? 1 : 0,
        transform: showR ? `translateY(0%)` : `translateY(-100%)`
      });
    
      const closeModal = e => {
        
        setShowR(false)
        
      };
    
      const keyPress = useCallback(
        e => {
          if (e.key === 'Escape') {
            setShowR(false)
            console.log('I pressed');
          }
        }
        
      );
    
      useEffect(
        () => {
          document.addEventListener('keydown', keyPress);
          return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
      );
      let navigate = useNavigate();

      const companyData = (e) =>{

     
        let pessoa = {
          
          DS_EMAIL: imovel.email,
          CLIENT_SECRET : "mudar123",
          DS_NOME: imovel.nome,
        }
        
        console.log(pessoa)
  
         api.post("/user/updatesenha",pessoa).then((response) => {
  
       
          
          api
          .post("/mail/criaruser", pessoa )
           
            Swal.fire({
              icon: "success",
              text: "E-mail Enviado com Sucesso!",
              timer: 5000,
            }).then(() => {
              window.location.reload();
            });   
             
         
         
         
  
  
          }).catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              text: "Serviço Temporariamente Indisponivel",
            });
          });
  
  
      
     
      }

    return (
        <>
       

            <span onClick={toggleViewModalr}
            className="reenviar"> <br></br> Reenviar  
												</span>



                        <Modal
        isOpen={modalr}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodalr(!modalr);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodalr(!modalr);
            }}
          >
           Reenviar E-mail
          </ModalHeader>
          <ModalBody>
          <div className="divin">
                  <span>Ao clicar em Reenviar, será disparado novamente o E-mail com os registros de Pré-cadastro para o Morador.</span>
                
                <br></br>
                </div>

                <div className="divin" style={{padding: '15px'}}>
                <Button
                color="info"
                className="btn-lg btn-primary"
               

                onClick={companyData}
                  
                >Reenviar </Button>
             
                </div>
            {/* <p className="mb-2">
            Email: <span className="text-primary">{imovel.email}</span>
            </p>
            <p className="mb-4">
            telefone: <span className="text-primary">{imovel.telefone ? imovel.telefone : "Aguardando Preenchimento do Morador"}</span>
            </p> */}

            
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodalr(!modalr);
              }}
            >
              Fechar
            </Button>
          </ModalFooter>
        </div>
      </Modal>
                
                
          {/* <Background onClick={handleClose} >
          <animated.div style={animation}>
          <Modal
                show={showR}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
            <ModalWrapper >
            <Modal.Header closeButton>
                    <Modal.Title>Reenviar E-mail</Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
              <ModalContent>
                <div className="divin">
                  <span>Ao clicar em Reenviar, será disparado novamente o E-mail com os registros de Pré-cadastro para o Morador.</span>
                
                <br></br>
                </div>
                <div className="divin">
                <Button
                color="info"
                className="btn-lg btn-primary"
               

                onClick={companyData}
                  
                >Reenviar </Button>
             
                </div>
               

                
              </ModalContent>
              
               </Modal.Body>
            </ModalWrapper>
            </Modal>
          </animated.div>
                    </Background> */}
                   
        
            
        </>
    );
}

export default VerMorador;
