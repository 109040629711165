import React, { useRef,useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,FormFeedback, Label, Form, Alert,InputGroup,
} from "reactstrap";
import classNames from "classnames";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { loginUser, socialLogin } from "../../store/actions";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AuthService from "../../services/Auth.service";
import BarChart from "../AllCharts/chartjs/barchart";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from 'react-redux';
import api from "../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";


import profile from "assets/images/profile-img.png";

import logonew from "assets/images/logonew.png";
import build from "assets/images/build2.png";
import lights from "assets/images/lights.png";

const LOGIN_URL = '/user/login';
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

//i18n
import { withTranslation } from "react-i18next";
const Loginnew = props => {


  const navigate = useNavigate();
  const errRef = useRef();

  const [DS_EMAIL, setUser] = useState('');
  const [CLIENT_SECRET, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setErrMsg('');
}, [DS_EMAIL, CLIENT_SECRET])

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
      await AuthService.login(DS_EMAIL.replace(" ",""), CLIENT_SECRET.replace(" ","")).then(
        () => {
          navigate("/")
          document.location.reload(true);
          window.location.reload();
          
        },
        (error) => {
          console.log(error);
          setErrMsg('E-mail ou senha inválidos');
        }
      );
    
  } catch (err) {
      if (!err?.response) {
          setErrMsg('Servidor err');
      } else if (err.response?.status === 400) {
          setErrMsg('E-mail ou senha inválidos');
      } else if (err.response?.status === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Login Failed');
      }
      // errRef.current.focus();
  }
}



  //meta title
  document.title = "Login | Syskeeper";

  return (
    <React.Fragment>
    <div className="account-pages my-5 pt-sm-5" style={{
      // Makes the container take full viewport height
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
     
    }}>
       <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))', // Solid black background
      }}></div>
      <div style={{
        position: 'absolute',
        top:'-100px',
        left: '-20%',
        right: 0,
        bottom: 0,
        backgroundImage: `url('${build}')`, 
        backgroundSize: 'cover',
        // backgroundPosition: '110% 0px',
        opacity: 1, // Adjust the transparency of the image
      }}></div>
       <div style={{
        position: 'absolute',
        top: 0,
       
        right: 0,
        bottom: 0,
        backgroundImage: `url('${lights}')`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        left: '10%',
        width: '80%',
        
        opacity: 1, // Full visibility for the light-colored image
      }}></div>
      <Container style={{paddingTop:'10%'}}>
        <Row className="justify-content-center">
          
     
          <Col md={8} lg={6} xl={5}>
          <div style={{
                  position: 'absolute',
                  top: '-100px', // Adjust this as needed
                  left: '50%',
                  transform: 'translateX(-50%)', // Center above the login area
                  width: '100%', // Ensures it aligns with the column width
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0)', // No background for a clean look
                  zIndex: '1000' // Ensuring it's on top of other elements
                }}>
                  <img
                    src={logonew}
                    alt="Logo"
                    style={{
                      maxHeight: '80px', // Limits the logo size
                      maxWidth: '70%' // Prevents the image from exceeding div width
                    }}
                  />
                </div>
            <Card className="overflow-hidden"  style={{ borderRadius:'15px' }}>
           
            <div className="bg-soft" style={{ position: 'relative' }}>
        
                <Row>
                  <Col xs={7}>
                    <div className="">
                    <h5 className="" style={{ fontSize: '1.8rem', padding:"30px 0px 0px 25px" }}>Bem-vindo!</h5>

                   
                    </div>
                  </Col>
             
                </Row>
              </div>
              <CardBody className="pt-0">
                {/* <div>
                  <Link to="/" className="logo-light-element">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                      <img
                          src={logo}
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </Link>
                </div> */}
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color:"red"}}>{errMsg}</p>

                <div className="p-2">
                  <form
                    className="form-horizontal"
                    onSubmit={handleSubmit} 
                  >
                
                    <div className="mb-3">
                    <InputGroup>
                    <div className="input-group-text" style={{ fontSize: '20px' }}> <i className="mdi mdi-login"></i></div>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="E-mail"
                        type="email"
                        id="DS_EMAIL"
                        onChange={(e) => setUser(e.target.value)}
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, background: '#F0F4F8',borderLeft:'None' }}
                        value={DS_EMAIL}
                        required
                       
                      />
                    </InputGroup>
                    </div>
                
                    <div className="mb-3">
                      {/* <Label className="form-label">Password</Label> */}
                      <InputGroup>
                      <div className="input-group-text" style={{ fontSize: '20px' }}><i className="mdi mdi-dots-horizontal"></i></div>
                      <Input
                        name="password"
                        placeholder="Senha"
                  
                        type="password"
                        id="CLIENT_SECRET"
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, background: '#F0F4F8',borderLeft:'None' }}
                    onChange={(e) => setPwd(e.target.value)}
                    value={CLIENT_SECRET}
                    required
                     
                      />
                        </InputGroup>
                    
                    </div>

                    <div className="form-check" style={{ color: "black" }} >
                    <a href="/recuperar" style={{ color: "black",fontSize: '0.8rem' }} >Esqueci minha Senha</a>
                  
                  
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                        style={{ height: '48px', fontSize: '1.2rem', borderRadius:"10px" }} 
                      >
                        Entrar
                      </button>
                    </div>


                  </form>
                </div>
              </CardBody>
            </Card>
           
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
  );
};


export default withTranslation()(Loginnew);
