import React, { useState, useEffect  } from "react";

import Button from "components/shared/Button/Button";
import { Col, Card, CardBody, Container, Row } from "reactstrap";
import Input from "components/shared/Inputs/Inputs";
import SearchTable from "components/shared/SearchTable/SearchTable";

import api from "../../services/api";
import Swal from "sweetalert2";
import { useNavigate, useParams ,Link } from "react-router-dom";
import AuthService from "../../services/Auth.service";
import Select from "react-select";


const Hidrometro = () => {
  const initialState = {
    number: "",
    desc: "",
    model: "",
    status: "S",
    nr:""
  };

  const user = AuthService.getCurrentUser();
  let navigate = useNavigate();



    let checkempresa = null;
    let DS_STATUS = null;
    if(user != null){
      
      DS_STATUS = user.user.DS_STATUS
    if(user.empresa[0] != undefined){
      checkempresa = user.empresa[0].OID_EMPRESA
    

    }
    }

  const statusArray = [
    // { id: "S", status: "Selecione..." },
    { id: "S", status: "Ativo" },
    { id: "N", status: "Inativo" },
  ];

  const Empresa = {
    OID_EMPRESA: "",
    NM_RAZAO_SOCIAL: "",
   
  };

  let stateempresa = null;


  const [selectedNumber, setSelectedNumber] = useState(null);

    const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = (number) => {
     // Prevent default form submission
    setSelectedNumber(number);
    setShowPopup(true);
  };

  const handleConfirm = () => {
    // Perform your function logic here
    console.log('Function executed with number:', selectedNumber);
    setShowPopup(false); 

    let bodyreg = {
      
      OID_HIDROMETRO: selectedNumber,
      
    }

    api.post("/hydrometers/filter/imovel", bodyreg).then(response => {
      console.log(response.data.length+"len")
      if (response.data.length > 0) {
       
        Swal.fire({
          icon: "warning",
          title: "O Medidor está vinculado a um imóvel; antes de deletar o Medidor, remova o vínculo.",
        });
      } 
      else{
        api.delete(`/hydrometers/${selectedNumber}`).then(() => {
          Swal.fire({
            icon: "success",
            title: "Medidor deletado!",
          })
            .then(() => {
              navigate("/cadastro/hidrometro");
              window.location.reload(true)
            })
            .catch(() => {
              Swal.fire({
                icon: "warning",
                title: "Dados Inválidos!",
              });
            });
        });

       
      }
    })



  };

  const handleCancel = () => {
    setShowPopup(false); // Close the popup without executing the function
  };

  const [selectedMed, setSelectedMed] = useState(null);
  const [selectedCond, setSelectedCond] = useState(null);

  
  const [values, setValues] = useState([]);
  const [showList, setShowList] = useState(false);

  let [lc, setLc] = useState (Empresa);

  const [search, setSearch] = useState(initialState);
  let [makers, setMakers] = useState([]);
  const [condos, setCondo] = useState([]);

  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const onChangeCondo = (prop) => (event) => {
    setLc({ ...lc, [prop]: event.target.value});

    stateempresa = event.target.value;
   
      fetchHidroCondo();
    
    
    
  };

  const fetchCondo = () => {
    let condo = [{ id: "",  condo: "Selecione..." }];
    api.get("/empresa/").then((response) => {
      response.data.map((elem) => {
        condo.push({
          value: elem.OID_EMPRESA,
          label: elem.NM_RAZAO_SOCIAL,
          
        });
      });
      setCondo(condo);
    });
  };

  const fetchMakers = () => {
    let maker = [{  maker: "Selecione..." }];
    api.get("/imovel/hidro/"+user.user.OID_USUARIO).then((response) => {
      response.data.map((elem) => {
        maker.push({
          value: elem.NR_MEDIDOR,
          label: "Medidor: "+elem.NR_MEDIDOR+" - "+elem.imovel,
         
         
          
        });
      });
      setMakers(maker);
    });
  };

  const fetchHidroCondo = () => {
    let maker = [{  maker: "Selecione..." }];
    api.get("/imovel/empresa/"+stateempresa).then((response) => {
      response.data.map((elem) => {
        maker.push({
          value: elem.NR_MEDIDOR,
          label: "Medidor: "+elem.NR_MEDIDOR+" - "+elem.imovel,
         
          
        });
      });
      setMakers(maker);
    });
  };


  const onChangeHandlerMed = (selectedOption) => {
    setSelectedMed(selectedOption);
    console.log('Selected Lora:', selectedOption.value);
  };

  const onChangeHandlerCond = (selectedOption) => {

    if (selectedOption === null) {
      fetchMakers();
      setSelectedCond(null)
      setSelectedMed(null);
    }
   else if (selectedOption.value) {
    setSelectedCond(selectedOption);

    stateempresa = selectedOption.value;
   
    fetchHidroCondo();
    console.log('Selected Lora:', selectedOption.value);
    }
  };


  useEffect(() => {
    fetchMakers();
  }, []);

  if(DS_STATUS == 10){
    useEffect(() => {
      fetchCondo();
    }, []);
  }


  const submitHandler = (event) => {
    event.preventDefault();
    if (!search.number && !search.desc && !search.model && !search.status) {
      Swal.fire({
        icon: "warning",
        text: "Preencha ao menos um campo para fazer uma pesquisa",
      });
      return;
    }

    if (!search.number && (search.desc || search.model) && !search.status) {
      Swal.fire({
        icon: "warning",
        text: "Preencha o campo Status para fazer uma pesquisa ",
      });
      return;
    }

    console.log(search)

    let body = {
      NR_MEDIDOR: selectedMed && selectedMed.value != "Selecione..." ? selectedMed.value : "",
      DS_DESCRICAO_MEDIDOR: search.desc,
      DS_MODELO_MEDIDOR: search.model,
      TP_ATIVO: search.status,
      OID_EMPRESA: selectedCond ? selectedCond.value :checkempresa,
    };

    api
      .post("/hydrometers/filter/todos", body)
      .then((response) => {
        let arr = formatArray(response.data);
        if (arr.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setShowList(false);
        } else {
          Swal.fire({
            icon: "success",
            timer: 500,
          });
          arr.map((elem) => {
            if (elem.status === "S") {
              elem.status = "Ativo";
            }
            if (elem.status === "N") {
              elem.status = "Inativo";
            }
          });

          console.log(arr);
          setValues(arr);
          setSearch(initialState);
          setShowList(true);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          text: "Erro Tente Novamente!",
        });
        setShowList(false);
      });
  };

  const formatArray = (data) => {
    let arr = [];
    data.map((elem) => {
      arr.push({
        id: elem.OID_MEDIDOR,
        number: elem.NR_MEDIDOR,
        desc: elem.DS_DESCRICAO_MEDIDOR,
        model: elem.DS_MODELO_MEDIDOR,
        consumption: elem.NR_CONSUMO_LITROS,
        status: elem.TP_ATIVO,
        condominio: elem.NM_RAZAO_SOCIAL
      });
    });
    return arr;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Pesquisa | Medidor</label>
        <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">
          {DS_STATUS == 10?
                  <Row className="">
                    
                   
                    <div className="col-6" style={{ textAlign: "start" }}>
                    <label
                      htmlFor="Condominio"
                      className="mt-1"
                    >
                      Condomínio
                    </label>
                    <Select
                      // id="fab-search"
                      // className="form-control form-control-lg"
                      // label="Número Medidor"
                      // options={[makers, "maker"]}
                      // value={search.maker}
                      // type="select"
                      // onChange={onChangeHandler("nr")}
                      // styles={{ width: "100%" }}
                      label="Condominio"
                      id="inlineFormSelectPrefc"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerCond}
                    styles={{
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={condos}
                    value={selectedCond}
                    isClearable
                    />
                    {/* <Input
                      id="fab-search"
                     
                      options={[condos, "condo"]}
                      value={lc.condo}
                      type="select"
                       onChange={onChangeCondo("condo")}
                      // onChange={e => { setLc("condo"); onChangeCondo("condo") }}
                      styles={{ width: "100%" }}
                    /> */}
                  </div>
                  <div className="col-6"  style={{ textAlign: "-webkit-right" }}>
                <Button 
                  icon="ti-agenda"
                  text="Cadastrar"
                  link="/cadastro/hidrometro-cadastro"
                />
              </div>
                  </Row>
                    : null  }

                   

                    <Row >
                    
                    
                    <div className="col-6" style={{ textAlign: "start" }}>
                    <label
                      htmlFor="Condominio"
                      className="mt-1"
                    >
                      Medidor
                    </label>
                    <Select
                      // id="fab-search"
                      // className="form-control form-control-lg"
                      // label="Número Medidor"
                      // options={[makers, "maker"]}
                      // value={search.maker}
                      // type="select"
                      // onChange={onChangeHandler("nr")}
                      // styles={{ width: "100%" }}
                      label="Número Medidor"
                      id="inlineFormSelectPref"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerMed}
                    styles={{
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={makers}
                    value={selectedMed}
                    />
                  </div>
                  
                  </Row>
            {/* <div className="row" style={{ textAlign: "end" }}>
              <div className="col-6" style={{ textAlign: "start" }}>
                <Input
                  id="fab-search"
                  label="Número Medidor"
                  value={search.number}
                  onChange={onChangeHandler("number")}
                  styles={{ width: "100%" }}
                />
              </div>
              
            
            
            </div> */}
            <div className="row">
              <div className="col-6">
              <label
                      htmlFor="Condominio"
                      className="mt-1"
                    >
                      Descrição
                    </label>
                <Input
                  id="desc"
               
                  value={search.desc}
                  onChange={onChangeHandler("desc")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
              <label
                      htmlFor="Condominio"
                      className="mt-1"
                    >
                      Modelo
                    </label>
                <Input
                  id="model"
                  
                  value={search.model}
                  onChange={onChangeHandler("model")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
              <label
                      htmlFor="Condominio"
                      className="mt-1"
                    >
                      Status
                    </label>
                <Input
                  id="fab-search"
               
                  type="select"
                  options={[statusArray, "status"]}
                  value={search.status}
                  onChange={onChangeHandler("status")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                <Button
                  icon="ti-search"
                  text="Pesquisar"
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                />
              </div>
            </div>
          </div>
        </form>
        {/* <SearchTable
          tableHead={[
            "Número Medidor",
            "Descrição",
            "Modelo",
            "Consumo",
            "Status",
            "",
          ]}
          tableBody={[values, 1]}
          showTable={showList}
        >
          {values?.map((maker, key) => (
            <td key={key}>
              <Link
                type="button"
                to={`/cadastro/hidrometro-update/${maker.id}`}
                color="link"
                size="sm"
                className="btn-light waves-effect waves-light"
              >
                <i className="dripicons-document-edit" />
              </Link>
            </td>
          ))}
        </SearchTable> */}
        {showList && (
          <Container fluid>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Número Medidor</th>
                  <th className="align-middle">Descrição</th>
                  <th className="align-middle">Modelo</th>
                  <th className="align-middle">Condomínio</th>
                  <th className="align-middle">Consumo</th>
                  
                  <th className="align-middle">Status</th>
                 
                  <th className="align-middle"></th>
                  <th className="align-middle"></th>
                  
                </tr>
              </thead>
              <tbody>
                {values?.map((value, key) => (
                  
                  <tr key={key}>
                  <td>{value.number}</td>
                  <td>{value.desc?value.desc :"Sem Descrição" }</td>
                  <td>{value.model}</td>
                  <td>{value.condominio}</td>
                  <td>{value.consumption}</td>
                  <td>{value.status}</td>

                 
                  
                  <td>
                    <Link
                      type="button"
                      to={`/cadastro/hidrometro-update/${value.id}`}
                      color="link"
                      size="sm"
                      className="btn-light waves-effect waves-light"
                    >
                      <i className="dripicons-document-edit" />
                    </Link>
                  </td>
                  <td>
                  <i onClick={() => handleButtonClick(value.id)} className="dripicons-trash" style={{ cursor: 'pointer' }} /> 
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Container>
          )}
          {showPopup && (
            <div className="modal-overlay">
              <div className="modal-content2">
                <p>Você realmente deseja deletar o Medidor?</p>
                <div className="modal-buttons">
                  <button className="confirm-button" onClick={handleConfirm}>Sim</button>
                  <button className="cancel-button" onClick={handleCancel}>Não</button>
                </div>
              </div>
            </div>
          )}
                  
     
      </div>
    </React.Fragment>
  );
};

export default Hidrometro;
