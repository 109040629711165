import React from "react";
import ReactDOM from 'react-dom';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import PropTypes from 'prop-types';

import store from "./store";

ReactDOM.render(

      <BrowserRouter>
       <Provider store={store}>
        <App />
     
   
        </Provider>
      </BrowserRouter>,
    document.getElementById('root')
);


serviceWorker.unregister()
