
import React, {  useRef, useState,useEffect } from 'react';

import AuthService from '../../services/Auth.service';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logoBranco from "../../assets/images/logo-sm.png";
import Swal from "sweetalert2";
import "./InitialPage.css";
import api from "../../services/api";
// const LOGIN_URL = '/user/login';

import logonew from "assets/images/logonew.png";
import build from "assets/images/build2.png";
import lights from "assets/images/lights.png";


process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
const EsqueciSenha = () => {
    // const { setAuth } = useAuth();

    const navigate = useNavigate();
    // const location = useLocation();
    // const from = location.state?.from?.pathname || "/";

    // const userRef = useRef();
    const errRef = useRef();

    const [DS_EMAIL, setUser] = useState('');
    const [CLIENT_SECRET, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

   
    useEffect(() => {
        setErrMsg('');
    }, [DS_EMAIL, CLIENT_SECRET])

    
    
   
    const handleSubmit = async (e) => {
        e.preventDefault();



        try {

          let body = {
            DS_EMAIL: DS_EMAIL,
         
          }


          api
          .post("/mail/rsenha", body )
          .then((response) => {

            Swal.fire({
              icon: "success",
              text: "Verifique sua caixa de e-mail!",
              timer: 50000,
            }).then(() => {
              
              navigate("/");
            });  
           
        }).catch(function (error) {
          console.log(error);
          Swal.fire({
            icon: "warning",
            text: "E-mail não encontrado",
          });
        });
          
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Servidor err');
            } else if (err.response?.status === 400) {
                setErrMsg('E-mail ou senha inválidos');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            // errRef.current.focus();
        }
    }

    return (

      <div className="bg-syswater">

        <div className="account-pages "style={{
      minHeight: '100vh', // Makes the container take full viewport height
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
     
    }}><div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))', // Solid black background
    }}></div>
    <div style={{
      position: 'absolute',
      top:'-100px',
      left: '-20%',
      right: 0,
      bottom: 0,
      backgroundImage: `url('${build}')`, 
      backgroundSize: 'cover',
      
      opacity: 1, // Adjust the transparency of the image
    }}></div>
     <div style={{
      position: 'absolute',
      top: 0,
     
      right: 0,
      bottom: 0,
      backgroundImage: `url('${lights}')`, 
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      left: '10%',
      width: '80%',
      
      opacity: 1, // Full visibility for the light-colored image
    }}></div>
        <div className="container">
  <div className="justify-content-center row">
    <div className="col-md-8 col-lg-6 col-xl-4">
    <div style={{
                  position: 'absolute',
                  top: '-50px', // Adjust this as needed
                  left: '50%',
                  transform: 'translateX(-50%)', // Center above the login area
                  width: '100%', // Ensures it aligns with the column width
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0)', // No background for a clean look
                  zIndex: '1000' // Ensuring it's on top of other elements
                }}>
                  <img
                    src={logonew}
                    alt="Logo"
                    style={{
                      maxHeight: '80px', // Limits the logo size
                      maxWidth: '70%' // Prevents the image from exceeding div width
                    }}
                  />
                </div>
      <div className="overflow-hidden imagembg">
        <div className="">
          <div className="text-center" style={{paddingTop:'20px'}}>
            <h5 className=" font-size-20">Esqueci a Senha!</h5>
            <p className="" style={{fontSize:'20px'}}>Digite o E-mail a baixo para recuperar a senha</p>
            
          </div>
        </div>
        <div className=" card-body" style={{padding:'0px 10px 10px 10px'}}>
          <div className="p-3">
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color:"red"}}>{errMsg}</p>
            <form onSubmit={handleSubmit}  className="form-horizontal mt-4 av-valid">
              <div className="mb-3">
                <div className="form-group">
                  <label  className="">Email</label>
                  <input
                  className=" is-pristine av-valid form-control"
                    type="email"
                    id="DS_EMAIL"
                    value={DS_EMAIL}
                    onChange={(e) => setUser(e.target.value)}
                    required
                />
                 
                </div>
              </div>
             
              <div className="mb-3 row">
              
                <div className=" col-sm-6">
                  <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Enviar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 
</div> 
       </div> 

    )
}

export default EsqueciSenha
