import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import Input from "components/shared/Inputs/Inputs";

import Button from "components/shared/Button/Button";
import Inputs from "components/shared/Inputs/Inputs";
import Swal from "sweetalert2";
import AuthService from "../../../services/Auth.service";
import { Col, Card, CardBody, Container, Row, Alert  } from "reactstrap";

const EquipamentoLoraUpdate = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  const initialState = {
    maker: "",
    desc: "",
    model: "",
    number: "",
  };

  const [edit, setEdit] = useState(initialState);
  const [makers, setMakers] = useState([]);

  const user = AuthService.getCurrentUser();



    let checkempresa = null;
    let DS_STATUS = null;
    if(user != null){
      
      DS_STATUS = user.user.DS_STATUS
    if(user.empresa[0] != undefined){
      checkempresa = user.empresa[0].OID_EMPRESA
    

    }
    }


  let stateempresa = null;
  const [emp, setEmp] = useState([]);
  const [condos, setCondo] = useState([]);
  const [reg, setReg] = useState([]);

  const fetchCondo = () => {
    let condo = [{ id: "",  condo: "Selecione..." }];
    api.get("/empresa/").then((response) => {
      response.data.map((elem) => {
        condo.push({
          id: elem.OID_EMPRESA,
          condo: elem.NM_RAZAO_SOCIAL,
          
        });
      });
      setCondo(condo);
    });
  };

  const fetchreg = () => {
    let bodyreg = {
      
      OID_EQUIPAMENTO_LORA: id,
      
    }
    api.post("/registros/filter", bodyreg).then(response => {
      console.log(response.data.length+"len")
      if (response.data.length > 0) {
       
        setReg(response.data.length)
      } 
    })
    console.log(reg)
  };

  useEffect(() => {
    fetchreg();
  }, []);

  if(DS_STATUS == 10){
    useEffect(() => {
      fetchCondo();
    }, []);
  }

  const onChangeCondo = (prop) => (event) => {

    
    
    setEmp({ condo: event.target.value })

    
   
      
    
      // fetchLoraEmpresa();
    
  };

  const fetchEquipLoraId = () => {
    api.get(`/equipmentslora/${id}`).then((response) => {
      let obj = response.data[0];
      obj = {
        maker: obj.OID_FABRICANTE_LORA,
        desc: obj.DS_DESCRICAO_EQUIPAMENTO,
        model: obj.DS_MODELO_EQUIPAMENTO,
        number: obj.NR_EQUIPAMENTO,
        empresa: obj.OID_EMPRESA
      };
      let obj2 = {

        condo: obj.empresa
      };
      setEdit(obj);
      if(obj.empresa){
        console.log("?",obj2)
        setEmp(obj2)
      }
    });
  };

  const fetchMakers = () => {
    let maker = [];
    api.get("/makerslora").then((response) => {
      response.data.map((elem) => {
        maker.push({
          id: elem.OID_FABRICANTE_LORA,
          maker: elem.DS_FABRICANTE_LORA,
        });
      });
      setMakers(maker);
    });
  };

  const onChangeHandler = (prop) => (event) => {
    setEdit({ ...edit, [prop]: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if(reg != 0)
    {
      Swal.fire({
        icon: "warning",
        title: "O módulo está vinculado a um kit; antes de alterar o módulo, remova o vínculo.",
      });
      return
    }

    const body = {
      OID_FABRICANTE_LORA: edit.maker,
      DS_DESCRICAO_EQUIPAMENTO: edit.desc,
      DS_MODELO_EQUIPAMENTO: edit.model,
      NR_EQUIPAMENTO: edit.number,
      OID_EMPRESA: emp.condo,
    };
    api.put(`/equipmentslora/${id}`, body).then(() => {
      Swal.fire({
        icon: "success",
        title: "Equipamento atualizado!",
      })
        .then(() => {
          navigate("/cadastro/modulo/equipamentolora");
        })
        .catch(() => {
          Swal.fire({
            icon: "warning",
            title: "Dados Inválidos!",
          });
        });
    });
  };

  useEffect(() => {
    fetchEquipLoraId();
    fetchMakers();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Editar | Equipamento Lora</label>
        <div>
          <Button
            icon="bx bx-left-arrow-circle"
            iconPosition="left"
            primaryColor="#f8f7ff"
            hoverColor="#f8f7ff"
            text="Voltar"
            link="/cadastro/modulo/equipamentolora"
            styles={{
              marginLeft: "1.5rem",
              background: "#f8f7ff",
              color: "blue",
            }}
          />
        </div>


        <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">
          {reg != 0?

          <Alert color="warning">
          <strong>Atenção!</strong> Módulo está vinculado a um kit, antes de alterar o módulo, retire o vinculo em kits.
          </Alert>

            : null  }
              {DS_STATUS == 10?
                 <div className="row">
                 <div className="col-6">
                   <Inputs
                     id="fab-edit"
                     label="Condominio"
                     options={[condos, "condo"]}
                     value={emp.condo}
                     type="select"
                     onChange={onChangeCondo("condo")}
                     styles={{ width: "100%" }}
                   />
                 </div>
               </div>
                  
                : null  }
              
            <div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-edit"
                  label="Fabricante"
                  options={[makers, "maker"]}
                  value={edit.maker}
                  type="select"
                  onChange={onChangeHandler("maker")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-register"
                  label="Descrição Equipamento"
                  value={edit.desc}
                  onChange={onChangeHandler("desc")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-register"
                  label="Modelo Equipamento"
                  value={edit.model}
                  onChange={onChangeHandler("model")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-register"
                  label="Número do Módulo (MAC ADDRESS)"
                  value={edit.number}
                  onChange={onChangeHandler("number")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
                <Button
                  icon="ti-agenda"
                  text="Atualizar"
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default EquipamentoLoraUpdate;
