import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const TermsOfAcceptance = () => {
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    console.log('Checkbox clicked:', e.target.checked); // Track the checkbox clicks
    setAccepted(e.target.checked);
  };


  // useEffect(() => {
  //   // Check if the user has already accepted the terms
  //   const hasAccepted = localStorage.getItem('termsAccepted');
  //   if (hasAccepted) {
  //     // Redirect to the dashboard if the terms have already been accepted
  //     navigate('/dashboard');
  //     window.location.reload()
  //   }
  // });

  const handleAccept = () => {
    if (accepted) {
      // Save acceptance to localStorage
      localStorage.setItem('termsAccepted', 'true');
      // Redirect to the dashboard
      navigate('/dashboard');
      window.location.reload()
    }
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Terms of Acceptance</h1>
      
      {/* Display the PDF */}
      <iframe
        src={`/termos.pdf`}
        width="100%"
        height="650px"
        title="Terms and Conditions"
      />

      {/* Checkbox to confirm acceptance */}
      <div style={{ marginTop: '20px' }}>
      <input
        type="checkbox"
        id="accept"
        // checked={accepted}
        onChange={handleChange}
        style={{ pointerEvents: 'auto', marginLeft:'10px' }}
      />
        <label htmlFor="accept"> Li e aceito os termos e condições.</label>
      </div>

      {/* Accept button */}
      <button
        onClick={handleAccept}
        disabled={!accepted}
        style={{ marginTop: '20px', padding: '10px 20px', cursor: accepted ? 'pointer' : 'not-allowed', marginBottom:'100px' }}
      >
        Aceitar e Continuar
      </button>
    </div>
  );
};

export default TermsOfAcceptance;