import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Row } from "reactstrap";
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});
const MouseCoordinates = ({ setCoordinates }) => {
  useMapEvents({
    mousemove(e) {
      setCoordinates([e.latlng.lat.toFixed(9), e.latlng.lng.toFixed(9)]);
    },
  });
  return null;
};
const Mapa = ({ Coordenadas }) => {
  const [location, setLocation] = useState([]);
  const [coordinates, setCoordinates] = useState([0, 0]);

  const position = [-18.918, -48.2766];
  useEffect(() => {
    const svgElements = document.querySelectorAll('svg');

    svgElements.forEach(svg => {
      svg.style.setProperty('display', 'none', 'important');
    });
  }, []);

  var coordDiv = document.getElementById('coordinates');

  return (
    <React.Fragment>
      <Row>
        <div style={{ marginTop: '70px' }}>
          <MapContainer center={position} zoom={12} style={{ height: '80vh', width: '100%' }} onclick={Coordenadas(coordinates)}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MouseCoordinates setCoordinates={setCoordinates} />
          </MapContainer>
          <div id="coordinates">
            Latitude: {coordinates[0]}, Longitude: {coordinates[1]}

          </div>
        </div>
      </Row>
    </React.Fragment>

  );
};

export default Mapa;