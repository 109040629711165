import React, { useState } from "react";

import Input from "components/shared/Inputs/Inputs";
import Button from "components/shared/Button/Button";
import {  Col, Container, Row } from "reactstrap";
import api from "../../services/api";
import Swal from "sweetalert2";
import { useNavigate , Link } from "react-router-dom";
import AuthService from "../../services/Auth.service";
import validator from 'validator';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import ReactInputMask from "react-input-mask";

const SindicoUpdate = () => {
  let navigate = useNavigate();
  const initialState = {
    nome: "",
    sobrenome: "",
    email: "",
    senha: ""
  };

  const { id } = useParams();

  const [adress, setAdress] = useState({})
  const [company, setCompany] = useState({})


   const [imovel, setImovel] = useState({})

   const [sindico, setSindico] = useState({})

  const user = AuthService.getCurrentUser();

  let checkempresa = null;
    if(user != null){
    if(user.empresa[0] != undefined){
      checkempresa = user.empresa[0].OID_EMPRESA
    }
    }

    if(id){
      checkempresa = id;
    }
    

    const {register,setValue,getValues} = useForm();

  // const onChangeHandler = (prop) => (event) => {
  //   setRegister({ ...register, [prop]: event.target.value });
  // };

  const errorAlert = (e) =>{
    Swal.fire({
      timer: 200000,
      text: e,
    });
  } 

  const checkCep = (e) =>{
    const cep = e.target.value.replace(/\D/g,'');
    if(cep.length ==8){
      fetch(`https://viacep.com.br/ws/${cep}/json/`).then(res =>res.json()).then(data=>{
        setValue('DS_LOGRADOURO',data.logradouro)
        setValue('DS_COMPLEMENTO',data.complemento)
        setValue('DS_BAIRRO',data.bairro)
        setValue('DS_CIDADE',data.localidade)
        setValue('DS_ESTADO',data.uf)


      })
   }
  }

  const submitHandler = (event) => {

    event.preventDefault();

    const personalInfo = getValues(["DS_NOME","DS_SOBRENOME","NR_TELEFONE","DS_EMAIL","CLIENT_SECRET","DS_CONFIRMASENHA"])
      let counter =0
      personalInfo.forEach(info=>{
        if(!info){
          counter++
        }
      })
      if(personalInfo[4] != personalInfo[5]){
        errorAlert("Favor verificar as senhas digitadas.")
        return
      }

      let cpf = getValues("DS_CPF")
      console.log(personalInfo)
      
      if(cpf != ""){
        cpf = cpf.replace(/\D/g,'');
      
      if(cpf.length < 11){
        errorAlert("Favor digitar um cpf válido.")
        return
      }
    }
    console.log(counter)


      if(counter <=1){
      
      }else{
        errorAlert("Favor preencher os dados obrigátorios!")
        return
      }

      setSindico({
        "DS_NOME":personalInfo[0],
        "DS_SOBRENOME":personalInfo[1],
        "NR_TELEFONE":personalInfo[2],
        "DS_EMAIL":personalInfo[3],
        "CLIENT_SECRET":personalInfo[4],
        "DS_CPF":getValues("DS_CPF")
       // aceitaContato:getValues("confirmaCheck")
      })

      let datasindico = {
        DS_NOME: getValues("DS_NOME"),
        DS_SOBRENOME: getValues("DS_SOBRENOME"),
        NR_TELEFONE: getValues("NR_TELEFONE"),
        DS_EMAIL: getValues("DS_EMAIL"),
        CLIENT_SECRET: getValues("CLIENT_SECRET"),
        


      }

      console.log(datasindico)

      
      api
       .post("/user/user", datasindico)
       .then((response) => {


        const companyData = 
        {
        "OID_USUARIO": response.data.OID_USUARIO
      
      
      }
        
      



      api
         .put("/empresa/"+id, companyData )
         .then((res) => {

          
          // setImovel({
          //   OID_EMPRESA: getValues(res.data[0].OID_EMPRESA)
     
          // })
        
      
         }).catch(function (error) {
           console.log(error);
           Swal.fire({
             icon: "warning",
             text: "Não foi possível realizar o cadastro da empresa",
           });
         });

        }).catch(function (error) {
          console.log(error);
          Swal.fire({
            icon: "warning",
            text: "Não foi possível realizar o cadastro da empresa",
          });
        });



  Swal.fire({
    icon: "success",
    text: "Síndico Cadastrado com sucesso!",
    timer: 16000,
  }).then(() => {
   
      navigate(`/listaMoradores/${id}`);
    
  }); 

 
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Cadastrar | Síndico</label>
       
              {!id ? (
        <div>
          <Button
            icon="bx bx-left-arrow-circle"
            iconPosition="left"
            primaryColor="#f8f7ff"
            hoverColor="#f8f7ff"
            text="Voltar"
            link="/condominios"
            styles={{
              marginLeft: "1.5rem",
              background: "#f8f7ff",
              color: "blue",
            }}
          />
        </div>
         ) : (
          <div>

          <Link
                        color=""
                        className="btn "
                        
                        to={`/listaMoradores/${id}`}
  
                        > <i className="mdi mdi-chevron-left" /> Voltar </Link>
                        </div>
  
            )}
        <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">
          <div className="mb-3 col-5">

          <h6 className="page-title">Informações do Síndico</h6>

<div className="form-group">
<label
        className=" col-form-label"
        >Nome* 
        </label>
        <input
                        {...register("DS_NOME")}
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                      />
</div>
</div>
<div className="mb-3 col-5">
<div className="form-group">
<label
        className="col-md-12col-form-label"
        >Sobrenome*
        </label>
        <input
                        {...register("DS_SOBRENOME")}
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                      />
</div>
</div>

<div className="mb-3 col-5">
<div className="form-group">
<label
        className="col-md-12col-form-label"
        >Telefone
        </label>
        <ReactInputMask
                        {...register("NR_TELEFONE")}
                        mask ="(99)99999-9999"
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                      />
</div>
</div>


<div className="mb-3 col-5">
<div className="form-group">
<label
        className="col-md-12col-form-label"
        >CPF
        </label>
        <ReactInputMask
                        {...register("DS_CPF")}
                        mask ="999.999.999-99"
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                      />
</div>
</div>

<div className="mb-3 col-5">
<div className="form-group">
<label
        className="col-md-12col-form-label"
        >Email*
        </label>
        <input
                        {...register("DS_EMAIL")}
                        className="form-control form-control-lg "
                        type="email"
                        placeholder="exemplo@email.com"
                        defaultValue=""
                      />
                      <p
                          {...register("validemail")}
                          />
</div>
</div>


<Row>
                      <Col lg={6}>
                    <label
                      className="col-md-12 col-form-label"
                    >Senha*
                    </label>
                      <input
                        {...register("CLIENT_SECRET")}
                        className="form-control form-control-lg w-100"
                        type="password"
                        defaultValue=""
                        maxLength={10}
                      />
                      </Col>
                      <Col lg={6}>
                    <label
                      className="col-md-12 col-form-label"
                    >Confirmar Senha*
                    </label>
                      <input
                        {...register("DS_CONFIRMASENHA")}
                        className="form-control form-control-lg "
                        type="password"
                        defaultValue=""
                        maxLength={10}
                      />
                     
                      
                      
                      </Col>
                      </Row>


           
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
                <Button
                  icon="ti-user"
                  text="Cadastrar"
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default SindicoUpdate;
