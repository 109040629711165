import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams  } from "react-router-dom";
import api from "../../services/api";
import ReactInputMask from "react-input-mask";
import Button from "components/shared/Button/Button";
import Inputs from "components/shared/Inputs/Inputs";
import { Col, Card, CardBody, Container, Row } from "reactstrap";
import AuthService from "../../services/Auth.service";
import Swal from "sweetalert2";

const HidrometroUpdate = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  const initialState = {
    number: "",
    desc: "",
    model: "",
    status: "",
    pulse: "",
  };

  const Empresa = {
    OID_EMPRESA: "",
    NM_RAZAO_SOCIAL: "",
   
  };

  const [condos, setCondo] = useState([]);
  let [lc, setLc] = useState (Empresa);
  

	const statusArray = [
    { id: "S", status: "Ativo" },
    { id: "N", status: "Inativo" },
  ];
  const [imovelid, setImo] = useState(null);

  const user = AuthService.getCurrentUser();

  let checkempresa = null;
    let DS_STATUS = null;
    if(user != null){
      
      DS_STATUS = user.user.DS_STATUS
    if(user.empresa[0] != undefined){
      checkempresa = user.empresa[0].OID_EMPRESA
    

    }
    }

  
  const [selectedNumber, setSelectedNumber] = useState(null);

  

  const [showPopup, setShowPopup] = useState(false);
 

  const handleButtonClick = (number) => {
     // Prevent default form submission
    setSelectedNumber(number);
    setShowPopup(true);
  };

  const [edit, setEdit] = useState(initialState);

  const fetchHydrometersId = () => {
    api.get(`/hydrometers/${id}`).then((response) => {
      let obj = response.data[0];
      obj = {
        id: obj.OID_MEDIDOR,
        number: obj.NR_MEDIDOR,
        desc: obj.DS_DESCRICAO_MEDIDOR,
        model: obj.DS_MODELO_MEDIDOR,
        pulse: obj.NR_CONSUMO_LITROS,
        status: obj.TP_ATIVO,
        oidimovel: obj.OID_IMOVEL,
        imovel: obj.IMOVEL,
      };
      setEdit(obj);
    });
  };

  const onChangeHandler = (prop) => (event) => {
    setEdit({ ...edit, [prop]: event.target.value });
  };

  const handleConfirm = () => {
    // Perform your function logic here
    console.log('Function executed with number:', selectedNumber);
    setShowPopup(false); 

    let zero = 0;

    let bodyup = {
      
      OID_IMOVEL: 1,
      
    };


    api
      .put(`/hydrometers/${id}`, bodyup)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Medidor atualizado!",
        }).then(() => {
          window.location.reload()
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          title: "Dados Inválidos!",
        });
      });



    // Close the popup after confirming
  };

  const onChangeHandlerimo = (prop) => (event) => {

    const selectedValue = event.target.value;
    console.log(selectedValue)
    let n = selectedValue
    setImo(n);
    console.log(imovelid)
  };

  const handleCancel = () => {
    setShowPopup(false); // Close the popup without executing the function
  };
  let [makers, setMakers] = useState([]);
  const [search, setSearch] = useState(initialState);


  const fetchCondo = () => {
    let condo = [{ id: "",  condo: "Selecione..." }];
    api.get("/empresa/").then((response) => {
      response.data.map((elem) => {
        condo.push({
          id: elem.OID_EMPRESA,
          condo: elem.NM_RAZAO_SOCIAL,
          
        });
      });
      setCondo(condo);
    });
  };

  if(DS_STATUS == 10){
    useEffect(() => {
      fetchCondo();
    }, []);
  }

  const onChangeCondo = (prop) => (event) => {
    
    setLc({ ...lc, [prop]: event.target.value});
    checkempresa = event.target.value;
   
  
    fetchimoCondo();
    
  };

  const fetchimoCondo = () => {
    let maker = [{ id: "",  maker: "Selecione..." }];
    api.get("/imovel/vagos/"+checkempresa).then((response) => {
      response.data.map((elem) => {
        maker.push({
          id: elem.OID_IMOVEL,
          maker: elem.imovel,
         
          
        });
      });
      setMakers(maker);
    });
  };

  useEffect(() => {
    fetchimoCondo();
    
  }, []);




  const submitHandler = (event) => {
    event.preventDefault();
    console.log(imovelid+"????????????")
    let body = {
      NR_MEDIDOR: edit.number,
      DS_DESCRICAO_MEDIDOR: edit.desc,
      DS_MODELO_MEDIDOR: edit.model,
      NR_CONSUMO_LITROS: edit.pulse,
      TP_ATIVO: edit.status,
      OID_IMOVEL: imovelid ? imovelid : ""
    };
    api
      .put(`/hydrometers/${id}`, body)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Medidor atualizado!",
        }).then(() => {
          window.location.reload()
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          title: "Imóvel já foi cadastrado em outro medidor!",
        });
      });
  };

  useEffect(() => {
    fetchHydrometersId();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Editar | Medidor</label>
        <div>
          <Button
            icon="bx bx-left-arrow-circle"
            iconPosition="left"
            primaryColor="#f8f7ff"
            hoverColor="#f8f7ff"
            text="Voltar"
            link="/cadastro/hidrometro"
            styles={{
              marginLeft: "1.5rem",
              background: "#f8f7ff",
              color: "blue",
            }}
          />
        </div>
        <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">
          {edit.oidimovel? (
            <>

              

              <Row className="">

                <label htmlFor="codigo" className="col-md-12 ">
                Imóvel
              </label>

                                  
              {/* <label
                htmlFor="Equipamento"
                className="col-md-2 col-form-label"
              >
                Imóvel
              </label> */}
              <div className="col-12" style={{ display: 'flex', alignItems: 'center' }}>

                {/* <h5 style={{ marginRight: '10px' }}>

                  {edit.imovel}

                </h5> */}

                <span className="graytext" style={{  width:"49%" }}>
                {edit.imovel}
              </span>

              {" "} <i onClick={() => handleButtonClick(edit.oidimovel)} className="dripicons-trash" style={{ cursor: 'pointer', paddingLeft:"10px", paddingRight:"10px" }} /> 
              {showPopup && (
              <div className="modal-overlay">
                <div className="modal-content2">
                  <p>Você realmente deseja retirar o Imóvel?</p>
                  <div className="modal-buttons">
                    <button className="confirm-button" onClick={handleConfirm}>Sim</button>
                    <button className="cancel-button" onClick={handleCancel}>Não</button>
                  </div>
                </div>
              </div>
              )}

                

              </div>


              </Row>        
              </>  

              ) : (
                <>
              <div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-register"
                  label="Condomínio"
                  type="select"
                  options={[condos, "condo"]}
                  value={lc.condo}
                  onChange={onChangeCondo("condo")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-register"
                  label="Imóvel *"
                  type="select"
                  options={[makers, "maker"]}
                  value={search.maker}
                  onChange={onChangeHandlerimo("maker")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
              </>
              )}
            <div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-edit"
                  label="Número Medidor"
                  value={edit.number}
                  onChange={onChangeHandler("number")}
                  styles={{ width: "100%" }}
                  
                />
              </div>
            </div>
            
						<div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-edit"
                  label="Descrição"
                  value={edit.desc}
                  onChange={onChangeHandler("desc")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
						<div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-edit"
                  label="Modelo"
                  value={edit.model}
                  onChange={onChangeHandler("model")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
						<div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-edit"
                  label="Pulso/Litro"
                  value={edit.pulse}
                  onChange={onChangeHandler("pulse")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            {/* <div className="row">
         
              <div className="col-6">
              <label  className="">Nr. Pulso <span ></span></label>
                <ReactInputMask
                  id="fab-edit"
                  label="Pulso/Litro"
                  mask = "999999"
                  value={edit.pulse}
                  onChange={onChangeHandler("pulse")}
                  className="form-control form-control-lg"
                  styles={{ width: "100%",border: "1px solid #dbdbdb",
                  borderRadius: "10px",
                  height: "1rem",
                  background: "ti-search",
                  paddingLeft: "1rem",
                  display: "inline", }}
                />
              </div>
            </div> */}
						<div className="row">
              <div className="col-6">
                <Inputs
                  id="fab-edit"
                  label="Status"
                  type="select"
                  options={[statusArray, "status"]}
									value={edit.status}
                  onChange={onChangeHandler("number")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
                <Button
                  icon="ti-agenda"
                  text="Atualizar"
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default HidrometroUpdate;
